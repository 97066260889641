<template>
  <!-- <body> -->

  <div>
    <router-view />
  </div>
</template>

<script>
//import profileHeader from "@/components/ProfileHeader.vue"
//import sideBar from "@/components/sideBar.vue"
//import footerView from "@/components/footer.vue"
export default {
  name: "layout",
  components: {
    //profileHeader,
    //sideBar,
    //footerView
  },
  data() {
    return {
      userdata: null,
    };
  },
  mounted() {
    this.log(this.showLoading);
    this.userdata = this.$store.getters["auth/getuser"];
  },
  computed: {
    showLoading: {
      // return this.$store.getters["isloading"];
      //return false;
      get() {
        return false;
        // return this.$store.getters["isloading"]
      },
      set(value) {
        this.value = value;
      },
    },
  },
  beforeCreate() {
    //console.log(this.showLoading);
  },
};
</script>
